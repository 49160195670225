@mixin base-reboot {
    line-height: $line-height-base;
    word-wrap: break-word;

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        @include font-inherit;
        margin: 0 0 $headings-margin-bottom 0;
        padding: 0;
        line-height: $headings-line-height;
        font-weight: $headings-font-weight;
    }
    h1, .h1 { @include font-size(32); }
    h2, .h2 { @include font-size(28); }
    h3, .h3 { @include font-size(24); }
    h4, .h4 { @include font-size(22); }
    h5, .h5 { @include font-size(20); }
    h6, .h6 { @include font-size(18); }

    p {
        @include font-inherit;
        margin: 0 0 16px 0;
        padding: 0;
        @include font-size(16);
    }

    a[href] {
        color: #208de5;

        &:hover {
            text-decoration: underline;
        }
    }

    ol, ul {
        @include font-inherit;
        color: inherit;
        margin: 0 0 16px 16px;
        ol, ul { margin-bottom: 0; }
    }
    ul { list-style: initial; }
    ol { list-style: decimal; }

    blockquote {
        @include font-inherit;
        margin: 0 0 16px;
        padding: 0 20px 2px 18px;
        border-left: 2px solid rgba($color: #000, $alpha: .2);
    }

    b, strong {
        font-weight: 700;
    }

    sub, sup {
        color: inherit;
        position: relative;
        vertical-align: baseline;
        line-height: 0;
        font-weight: inherit;
        font-size: 75%;
    }
    sub { bottom: -4px; }
    sup { top: -8px; }

    pre, code {
        @include font-inherit;
        @include font-size(16);
        background: rgba($color: #000, $alpha: .2);
        margin: 0 0 16px 0;
        padding: 8px 12px;
        overflow: auto;
        -ms-overflow-style: scrollbar;
        font-weight: 300;
        font-size: 80%;
    }
}