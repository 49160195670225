.announcement {
    &--modal {
        color: #fff;
        max-width: 768px;
        font-weight: 500;

        .modal-content {
            background-color: #222222;
            border: 0;
            border-radius: 0;
        }

        .modal-header {
            padding: 5px 40px 10px;
        }

        &--close {
            background-color: #fff;
            color: #222222;
            width: 30px;
            max-width: 30px;
            min-width: 30px;
            height: 30px;
            max-height: 30px;
            min-height: 30px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 24px;
            border-radius: 0;
        }

        &--title {
            margin:0;
            padding: 0;
            line-height: 1.2;
            font-weight: 700;
            font-size: 30px;

            @media screen and (max-width: 768px) {
                font-size: 20px;
            }
        }

        &--subtitle {
            margin-top: 5px;
            padding: 0;
            line-height: 1.2;
            font-size: 16px;
        }

        .modal-body {
            padding: 20px 20px 30px;

            &--head {
                display: flex;
                margin-bottom: 20px;

                &--left {
                    margin-right: 20px;
                    flex: auto;
                    display: flex;
                    flex-direction: column;
                }

                &--title {
                    line-height: 1.2;
                    font-weight: 600;
                    font-size: 18px;
                }

                &--status {
                    display: flex;
                    margin-top: 5px;

                    .badge {
                        color: #fff;
                        background-color: #ffb007;
                        margin-right: 5px;
                        margin-top: 5px;
                        padding: 1.5px 4.8px 3px;
                        line-height: 1.4;
                        font-size: 12px;
                    }
                }

                &--date {
                    line-height: 1.6;
                    font-size: 16px;
                    white-space: nowrap;
                }
            }

            &--content {
                @include base-reboot;
                padding-top: 10px;
                padding-bottom: 20px;
                text-align: left;
                font-weight: 400;
                font-size: 16px;
                line-height: 1.4;
                white-space: pre-wrap;
                border-top: 1px solid rgba(192, 192, 192, 0.4);
                border-bottom: 1px solid rgba(192, 192, 192, 0.4);
            }

            &--foot {
                margin-top: 20px;
                display: flex;
                justify-content: flex-end;

                &--btn {
                    color: #fff;
                    background-color: #6c757d;
                    padding: 4px 32px;
                    font-size: 14px;
                    border-radius: 0;
                }
            }
        }
    }
}