.corporate-member {
    .table-tag {
        &-width {
            max-width: 26rem;
        }

        &-gap {
            gap: 5px;
        }
    }

    .mms-custom-card {
        z-index: 2;
    }

    .mms-other-custom-card {
        z-index: 1;
    }

    .mms-custom-select {
        z-index: 3;
    }

    .mms-other-custom-select {
        z-index: 4;
    }

    .mms-custom-select-ZIndex-5 {
        z-index: 5;
    }

    .react-datepicker-popper {
        z-index: 6;
    }
}
