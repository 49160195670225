.table {

    tbody tr {
        color: #212a44;
        background-repeat: no-repeat;
    }

    thead tr th,
    tbody tr td {
        border-color: #d1d1dd;
    }

    thead tr th {
        background-color: map-get($map: $theme-colors, $key: light);
        padding: 0.375rem 0.75rem;
    }

    .td-action {
        display: flex;
        justify-content: flex-end;

        .btn {
            min-width: 30px;
        }
    }

    &--thumb {
        width: 3.125rem;
        height: 1.875rem;
        object-fit: cover;
        object-position: center;
        border-radius: .25rem;

        &.square {
            height: 3.125rem;
            border-radius: 50%;
        }
    }

    td {
        padding-top: 10px;
        padding-bottom: 10px;

        &.url-max-width {
            max-width: 180px;
            white-space: normal;
            a{
                white-space: normal !important;
                width: 100%;
                max-width: 100%;
                text-align: left;
            }
        }

        &.site-min-width {
            min-width: 70px !important;
        }
    }
}

.table-striped {

    tbody tr {
        background-color: #f8f7ff;
    }

    tbody tr:not(:hover):nth-of-type(odd) {
        background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    }

    tbody tr:hover > td {
        background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075));
    }

    thead tr th {
        border-top: 0;
        border-bottom: 0;
    }
}

.table-responsive:last-child {

    .table {
        margin-bottom: 0;
    }
}