$block-class: '.selectgroup' !default;

$block-properties: (
    'button-color-active': if($primary, $primary, #007bff),
    'button-padding-y': if($btn-padding-y, $btn-padding-y, .375rem),
    'button-padding-x': if($btn-padding-x, $btn-padding-x, .75rem),
    'button-padding-y-sm': if($btn-padding-y, $btn-padding-y, .25rem),
    'button-padding-x-sm': if($btn-padding-x, $btn-padding-x, .5rem),
    'button-border-radius': if($btn-border-radius, $btn-border-radius, .25rem),
) !default;

#{$block-class} {
    display: inline-flex;
    margin-bottom: 20px;

    &__item {
        flex-grow: 1;
        position: relative;
        margin: 0;

        &:not(:first-child) {
            #{$block-class}__button {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
        &:not(:last-child) {
            #{$block-class}__button {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        & + & {
            margin-left: -1px;
        }
    }

    &__input {
        opacity: 0;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;

        &:checked {
            & + #{$block-class}__button {
                background-color: map-get($map: $block-properties, $key: 'button-color-active');
                color: #fff;
                z-index: 1;
            }
        }
    }

    &__button {
        cursor: pointer;
        background-color: #fdfdff;
        min-width: 2.375rem;
        padding: map-get($map: $block-properties, $key: 'button-padding-y') map-get($map: $block-properties, $key: 'button-padding-x');
        display: block;
        position: relative;
        font-weight: 600;
        text-align: center;
        border: 1px solid #e4e6fc;
        border-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        user-select: none;
    }
}