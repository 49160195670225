$height: 225px;

.medlib-panel {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    user-select: none;

    @media screen and (max-width: 991px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    &__left {
        width: 100%;
        height: $height;
        display: block;
        flex: 1;
        margin: 20px;
        margin-right: 0;
        overflow-x: hidden;
        overflow-y: auto;

        @media screen and (max-width: 991px) {
            width: calc(100% - 20px);
            flex: auto;
        }
        @media screen and (max-width: 360px) {
            height: 180px;
        }

        &__imageContainer {
            width: calc(100% + 20px);
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;

            @media screen and (max-width: 991px) {
                width: calc(100%);
                margin: 0 -2.5%;
            }
        }

        &__imageLoading {
            width: 100%;
            margin: 5px 10px;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.025rem;

            @media screen and (max-width: 991px) {
                margin: 5px 2.5%;
            }
        }

        &__imageBox {
            width: 100px;
            height: 100px;
            margin: 5px 10px;
            position: relative;   
            overflow: hidden;
            text-align: center;
            border: 1px solid rgba($color: gray, $alpha: 0.6);
            border-radius: 3px;
            @media screen and (max-width: 991px) {
                width: 20%;
                margin: 5px 2.5%;
            }
            @media screen and (max-width: 460px) {
                height: 80px;
            }
            @media screen and (max-width: 360px) {
                height: 60px;
            }

            &__image {
                width: 100%;
                height: 100%;
                object-fit: contain;
                -o-object-fit: contain;
                object-position: center;
                -o-object-position: center;
                z-index: -1;
            }

            &__label {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                margin: 0;
                cursor: pointer;
                user-select: none;

                &:hover {
                    background-color: rgba($color: gray, $alpha: 0.2);
                }
            }
            
            &__checkbox {
                position: absolute;
                right: 5px;
                top: 5px;
                display: block;
                margin: 0;

                .custom-control-label::before,
                .custom-control-label::after { top: 0; }
            }

            
        }
    }

    &__right {
        width: 295px;
        padding: 20px;
        @media screen and (max-width: 991px) {
            width: 100%;
            padding-bottom: 0;
        }

        &__dropbox {
            width: 100%;
            height: $height;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 15px 20px;
            text-align: center;
            border: 2px dashed rgba($color: gray, $alpha: 0.4);
            overflow: hidden;
            cursor: pointer;

            &:hover {
                border: 2.4px dashed rgba($color: map-get($theme-colors, 'primary'), $alpha: 0.8);
            }

            &__prevImage {
                height: 145px;
                width: 100%;
                object-fit: contain;
                object-position: center;
                -o-object-fit: contain;
                -o-object-position: center;
            }
        }
    }
}