%customSwitch,
.custom-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 32px;

    &__slider {
        cursor: pointer;
        background-color: #ccc;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: .4s;
        border-radius: 34px;
        box-shadow: rgba(0, 0, 0, 0.175) 0px 3px 5px 0px inset;

        &::before {
            content: "";  
            background-color: #ffffff;
            width: 26px;
            height: 26px;
            display: flex;
            align-items: center;
            position: absolute;
            left: 3px;
            top: 50%;
            bottom: 50%;
            transform: translate(0,-50%);
            border-radius: 50%;
            animation: toggleSwitchOffAnimate .28s ease;
        }
    }

    input { 
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked + &__slider {
        background-color: #2196F3;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 5px 0px inset;
    }

    input:checked + &__slider::before {
        left: calc(100% - 29px);
        animation: toggleSwitchOnAnimate .28s ease;
    }
}

.custom-switch-sm {
    @extend %customSwitch;
    width: 42px;
    height: 24px;

    .custom-switch__slider::before {
        width: 20px;
        height: 20px;
        animation: toggleSwitchOffAnimateSm .2s ease;
    }

    input:checked + .custom-switch__slider::before {
        left: calc(100% - 23px);
        animation: toggleSwitchOnAnimateSm .2s ease;
    }
}

@keyframes toggleSwitchOnAnimate {
    from { left: 3px; }
    to { left: calc(100% - 29px); }
}
@keyframes toggleSwitchOffAnimate {
    from { left: calc(100% - 29px); }
    to { left: 3px; }
}
@keyframes toggleSwitchOnAnimateSm {
    from { left: 3px; }
    to { left: calc(100% - 23px); }
}
@keyframes toggleSwitchOffAnimateSm {
    from { left: calc(100% - 29px); }
    to { left: 3px; }
}
