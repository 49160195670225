// Font Weight
@each $value in (500, 600, 700) {
    .font-#{$value} { font-weight: $value !important; }
}

// Font Size
@each $value in (12, 13, 14, 16, 18, 20, 24) {
    .font-#{$value} { font-size: #{$value / 16}rem !important; }
}

// Line Height
@each $key, $value in ('1': 1, 'sm': 1.3, 'base': $line-height-base) {
    .lh-#{$key} { line-height: $value !important; }
}