.segment-card {
    position: relative;
}

.segment-card-body {
    background-color: rgba(0, 0, 0, 0.05);
    position: relative;
    margin-top: 75px;
    padding: 20px;
    border-radius: 0.25rem;
    box-shadow: .5px 1px 4px .5px #999;

    &::before {
        content: "AND";
        color: #ffffff;
        background-color: rgba(30, 136, 229, 1);
        display: block;
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: .875rem;
        font-weight: 600;
        text-align: center;
        border-radius: 50%;
        transform: translate(0, -60px);
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
    }

    &:nth-child(1),
    &:nth-of-type(1) {
        margin-top: 0px;

        &::before {
            content: "";
            display: none !important;
        }
    }

    &__nav {
        background-color: #ffffff;
        margin: 20px -20px -20px;
        padding: 20px;
        border-radius: 0 0 0.25rem 0.25rem;
    }
}

.segment-btn-addnew {
    background-color: rgba(30, 136, 229, 0.1);
    color: rgba(30, 136, 229, 1);
    display: inline-block;
    font-size: .875rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 30px;

    &:hover,
    &.active {
        color: #ffffff;
    }
}