/* Font Weight */
$fontWeight: 500, 600, 700, bold;
@each $value in $fontWeight {
    .font-weight-#{$value} { font-weight: $value !important; }
}

/* z-index */
$zIndex: 0, 1, 5;
@each $value in $zIndex {
    .index-#{$value} { z-index: $value !important; }
}

/* Font Size */
$fontSize: 12, 14, 16, 18, 20, 22, 24;
@each $value in $fontSize {
    .font-size-#{$value} { font-size: #{$value}px !important; }
}

/* Spacing */
$mSpacer: 5px !default;
$mSpacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($mSpacer * 1),
    2: ($mSpacer * 2),
    3: ($mSpacer * 4),
    4: ($mSpacer * 6),
    5: ($mSpacer * 10),
    6: ($mSpacer * 12),
    7: ($mSpacer * 14),
    8: ($mSpacer * 26)
  ), $mSpacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    60: 60%,
    70: 70%,
    75: 75%,
    90: 90%,
    100: 100%,
    auto: auto
  ),
  $sizes
);
$minWidths: 0, 50, 100;
@each $val in $minWidths {
    .min-w-#{$val} {
        min-width: #{$val}% !important;
    }
}

// Typography
$font-weight-base: 400;
$line-height-base: 1.5;

$headings-margin-bottom: 8px;
$headings-font-weight: 700;
$headings-line-height: 1.2;