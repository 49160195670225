.file-attachment {

    &__panel {
        width: calc(100% + 10px);
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
    }

    &__items {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
        height: 50px;
        min-height: 50px;
        max-height: 50px;
        position: relative;
        overflow: hidden;
        margin: 5px;
        overflow: hidden;

        &__image {
            width: 100%;
            height: 100%;
            padding: 4px 2px;
            object-fit: contain;
            object-position: center;
            border: 2px solid rgba($color: gray, $alpha: 0.2);
            border-radius: 2px;
        }
        
        &__delete {
            background-color: rgba($color: map-get($map: $theme-colors, $key: 'danger'), $alpha: 0.7);
            color: #ffffff;
            width: 100%;
            height: 100%;
            display: none;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 20px;
            opacity: 0;
            transition: opacity 0.4s ease-in-out;
        }

        &__progress {
            background-color: transparent;
            color: rgba($color: gray, $alpha: 0.8);
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            border: 2px solid rgba($color: gray, $alpha: 0.2);
            border-radius: 2px;
        }

        &:hover>&__delete {
            display: flex;
            opacity: 1;
            border-radius: 2px;
            cursor: pointer;
            transition: opacity 0.4s ease-in-out;
        }
    }

    &__add {
        color: rgba($color: gray, $alpha: .4);
        width: 50px;
        min-width: 50px;
        max-width: 50px;
        height: 50px;
        min-height: 50px;
        max-height: 50px;
        position: relative;
        z-index: 2;
        display: block;
        border: 2px dashed rgba($color: gray, $alpha: 0.4);
        cursor: pointer;
        transition: color 0.125s linear, border 0.125s linear;

        &:hover {
            color: rgba($color: map-get($theme-colors, 'primary'), $alpha: .8);
            border: 2px dashed rgba($color: map-get($theme-colors, 'primary'), $alpha: 0.8);
            transition: color 0.125s linear, border 0.125s linear;
        }
        &:hover~&__icon {
            color: rgba($color: map-get($theme-colors, 'primary'), $alpha: .8);
            transition: color 0.125s linear, border 0.125s linear;
        }

        &__icon {
            color: rgba($color: gray, $alpha: .4);
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: color 0.125s linear, border 0.125s linear;
        }
    }
}