// Fonts
@mixin font-size ($fontSize: 16) {
    // font-size: $fontSize / 16 * 1 + em;
    font-size: $fontSize + px;
}

@mixin font-reset () {
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    line-height: $line-height-base;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
}

@mixin font-inherit () {
    color: inherit;
    @include font-reset;
    font-size: inherit;
}

@import 'mixins/base_reboot';