.swal2-popup {
    width: 25em !important;

    &-lg {
        width: 40em !important;
    }
}

.swal2-icon {
    // width: 3.5rem !important;
    // height: 3.5rem !important;
    margin: 0em auto .25em !important;
    transform: scale(.75) !important;

    // &-content {
    //     font-size: 2.5em !important;
    // }

    &.swal2-primary { color: color(primary) !important; }
    &.swal2-success { color: color(success) !important; }
    &.swal2-info { color: color(info) !important; }
    &.swal2-warning { color: color(warning) !important; }
    &.swal2-danger { color: color(danger) !important; }
    &.swal2-error {
        color: color(danger) !important;
        border-color: color(danger) !important;
        // [class^="swal2-x-mark-line"] {
        //     background-color: color(danger) !important;
        //     width: 2.25em !important;
        //     top: 1.6em !important;

        //     &[class$="left"] { left: .65em !important; }
        //     &[class$="right"] { right: .65em !important; }
        // }
    }
}

.swal2-close {
    background-color: transparent !important;
    width: 1em !important;
    height: 1em !important;
    font-size: 2em !important;
    border: none !important;
    box-shadow: none !important;
}

.swal2-title {
    margin: 0 !important;
    font-size: 1.125em !important;
}

.swal2-content {
    font-size: 1em !important;
}

.swal2-actions {

    .swal2-confirm,
    .swal2-deny,
    .swal2-cancel {
        @extend .btn;
        @extend .btn-sm;
        margin-left: map-get($map: $spacers, $key: 1);
        margin-right: map-get($map: $spacers, $key: 1);
    }

    .swal2-confirm  { @extend .btn-primary; }
    .swal2-deny     { @extend .btn-danger; }
    .swal2-cancel   { @extend .btn-danger; }

    .swal2-styled {
        min-width: 5em;
        outline: none !important;

        &.swal2-confirm,
        &.swal2-deny,
        &.swal2-cancel {
            @extend .btn;
            @extend .btn-sm;
            margin-left: map-get($map: $spacers, $key: 1);
            margin-right: map-get($map: $spacers, $key: 1);
            font-weight: 500;
            font-size: 0.875rem;
            letter-spacing: .2px;
        }

        &.swal2-confirm  { @extend .btn-primary; }
        &.swal2-deny     { @extend .btn-danger; }
        &.swal2-cancel   { @extend .btn-danger; background-color: color(danger) !important; }
    }
}