.article-donation-page {
    .react-datepicker__close-icon {
        padding: 0px 6px 2px 0px !important;
        &::after {
            padding: 0px !important;
            display: block !important;
            color: #3C64A7 !important;
            background-color: #fff !important;
            font-size: 17px !important;
            font-weight: 700 !important;
            width: auto !important;
            height: auto !important;
        }
    }
}